<!-- 中心介绍 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
    </div>
    <div class="content_c">
    <div>
      <!--百度地图容器-->

  <baidu-map class="map" :center="{lng: 116.404, lat: 39.915}" :zoom="15">
    <bm-marker :position="{lng: 116.404, lat: 39.915}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
      <bm-label content="" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
    </bm-marker>
  </baidu-map>
   </div>
      <div class="from">
        <div class="cart-small-heading">
          <div class="font-24">联系我们</div>
        </div>
        <!-- <form class="needs-validation"> -->
        <el-form
          class="form"
          ref="form"
          :model="form"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input
              type="textarea"
              v-model="form.content"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
        <!-- </form> -->
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { province, addMsg } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      userInfo: "",
      form: {
        title: "",
        phone: "",
        content: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    center: {lng: 0, lat: 0},
      zoom: 3
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    // this.baiduMap();
  },
  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    // 提交留言
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = await addMsg(this.form);
          if (res.ResultCode == 0) {
            this.$message.success("提交成功");
            this.form = {
              title: "",
              phone: "",
              content: "",
            };
            console.log(res, "res");
          }
        } else {
          this.$message.error("提交失败");
          return false;
        }
      });
    },
        handler ({BMap, map}) {
      console.log(BMap, map)
      this.center.lng = 116.404
      this.center.lat = 39.915
      this.zoom = 15
    }
  },
};
</script>

<style lang="scss" scoped>
// .map {
//   width: 100%;
//   height: 400px;
// }
.box {
  width: 100%;
  box-sizing: border-box;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size:cover;
    box-sizing: border-box;
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
    .bom {
      margin: 0 250px;
      margin-top: 207px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .list_style {
        padding-right: 137px;
        .title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }
        .title2 {
          font-size: 34px;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
  .content_c {
    width: 65%;
    min-width: 800px;
    margin: 150px auto;
    overflow: hidden;
    display: flex;
    .map {
      width: 483px;
      height: 483px;
      text-align: center;
      line-height: 483px;
      margin: 20px;
      background-color: #eee;
    }
    /* 留言 */
    .from {
      margin: 20px 0;
      width: 687px;
      height: 483px;
      background: #ffffff;
      box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);

      .cart-small-heading {
        padding-left: 20px;
        height: 50px;
        line-height: 50px;
        margin: 30px 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        border-bottom: 1px solid #e5e5e5;
      }
      .form {
        margin-right: 30%;
      }
    }
  }
  .content5 {
    width: 100%;
    height: 310px;
    background: #f5f5f5;
  }
}
</style>
